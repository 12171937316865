import React, { Component } from 'react';

class LeaderboardRow extends Component {
  render() {
    const { rank, battleTag, rating } = this.props;

    return (
      <tr>
        <th scope="row" className={'rank rank-' + rank}>
          {rank}
        </th>
        <td>{battleTag}</td>
        {rating && <td>{rating}</td>}
      </tr>
    );
  }
}

export default LeaderboardRow;
