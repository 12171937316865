import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from './reducer';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();

  middlewares.push(loggerMiddleware);
}

export const store = createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunkMiddleware, ...middlewares)
);

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});
