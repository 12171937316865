import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import LeaderboardRow from './LeaderboardRow';
import Spinner from '../Spinner/Spinner';
import { capitalize } from '../../utils';

class LeaderboardTable extends Component {
  filterRows(leaderboard, filter) {
    return _.filter(leaderboard.rows, row => {
      const battleTag = row.accountid;

      return battleTag.toLowerCase().includes(filter.toLowerCase());
    });
  }

  render() {
    const { loading, leaderboard, errors, filter } = this.props;

    if (loading) {
      return (
        <div className="loading-container">
          <Spinner />
        </div>
      );
    }

    if (errors.length > 0) {
      return this.renderErrors();
    }

    const rows = this.filterRows(leaderboard, filter);
    const columns = leaderboard.columns;

    return (
      <div className="row">
        <div className="col">
          <table className="table table-striped table-hs">
            <thead>{this.renderColumns(columns)}</thead>
            <tbody>{this.renderRows(rows, columns)}</tbody>
          </table>
        </div>
      </div>
    );
  }

  renderErrors() {
    return (
      <div className="loading-container text-center">
        <h2>Whoops! Something went wrong.</h2>
        {this.props.errors.map((error, i) => {
          return (
            <p key={i} className="m-0">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  renderColumns(columns) {
    return (
      <tr>
        {columns.map((column, i) => {
          let label = column;
          if (label === 'accountid') {
            label = 'BattleTag';
          }

          return (
            <th
              key={column}
              scope="col"
              className={i === 0 ? 'text-center' : ''}
            >
              {capitalize(label)}
            </th>
          );
        })}
      </tr>
    );
  }

  renderRows(rows, columns) {
    if (rows.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={columns.length}>No players to display.</td>
        </tr>
      );
    }

    return rows.map(row => {
      const rank = row.rank;
      let rating;
      if (row.rating) {
        rating = row.rating;
      }
      const battleTag = row.accountid;
      const id = rank + '-' + battleTag;

      return (
        <LeaderboardRow
          key={id}
          rank={rank}
          battleTag={battleTag}
          rating={rating}
        />
      );
    });
  }
}

function mapStateToProps(state) {
  const { loading, leaderboardData, errors } = state.leaderboard;
  const leaderboard = leaderboardData.leaderboard;

  return {
    loading,
    leaderboard,
    errors
  };
}

export default connect(mapStateToProps)(LeaderboardTable);
