import { leaderboardConstants } from './Leaderboard.constants';

const initialState = {
  loading: true,
  errors: [],
  leaderboardData: {}
};

export function leaderboard(state = initialState, action) {
  switch (action.type) {
    case leaderboardConstants.GET_LEADERBOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leaderboardConstants.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        leaderboardData: action.leaderboardData
      };
    case leaderboardConstants.GET_LEADERBOARD_FAILURE:
      let newState = Object.assign({}, state);
      let errors = newState.errors;
      let error = 'Error fetching leaderboard.';
      errors.push(error);

      return {
        ...state,
        loading: false,
        errors: errors
      };
    default:
      return state;
  }
}
