import { leaderboardService } from './Leaderboard.service';
import { leaderboardConstants } from './Leaderboard.constants';

export const leaderboardActions = {
  getLeaderboard
};

function getLeaderboard(region, leaderboardId, seasonId) {
  return dispatch => {
    dispatch(request());

    leaderboardService.getLeaderboard(region, leaderboardId, seasonId).then(
      response => {
        const leaderboardData = response.data;

        dispatch(success(leaderboardData));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: leaderboardConstants.GET_LEADERBOARD_REQUEST };
  }

  function success(leaderboardData) {
    return {
      type: leaderboardConstants.GET_LEADERBOARD_SUCCESS,
      leaderboardData
    };
  }

  function failure(error) {
    return { type: leaderboardConstants.GET_LEADERBOARD_FAILURE, error };
  }
}
