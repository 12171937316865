import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Countdown from 'react-countdown-now';

import { formatLastUpdated } from '../../utils';
import { leaderboardIds } from './Leaderboard.constants';

class LeaderboardHeading extends Component {
  getTimerEnd(startTime, now) {
    const start = moment(startTime).utc();
    const end = moment(start)
      .utc()
      .add(1, 'M');
    const diff = end.diff(now);
    const timerEnd = now + diff;

    return timerEnd;
  }

  render() {
    const { leaderboardInfo, lastUpdated, startTime } = this.props;

    const updated = lastUpdated ? formatLastUpdated(lastUpdated) : '-';
    const now = moment();
    const timerEnd = this.getTimerEnd(startTime, now);
    const timeRemaining = startTime ? (
      <Countdown key={now} date={timerEnd} />
    ) : (
      '-'
    );

    return (
      <div className="row">
        <div className="col-7">
          <h1>{leaderboardInfo.title}</h1>
          <p>{leaderboardInfo.description}</p>
        </div>
        <div className="col-5 text-right">
          <p className="season-details">
            <span className="last-updated">
              <strong>Last updated:</strong> {updated}
            </span>
            <br />
            <span className="countdown">
              <strong>Time remaining:</strong> {timeRemaining}
            </span>
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { leaderboardData } = state.leaderboard;
  const leaderboard = leaderboardData.leaderboard;

  let lastUpdated = '';
  if (leaderboard && leaderboard.metadata) {
    lastUpdated = leaderboard.metadata.last_updated_time;
  }

  const leaderboardId = leaderboard
    ? leaderboardIds[leaderboard.leaderboard_id]
    : '?';

  let leaderboardInfo = {
    title: `Top 200 - ${leaderboardId}`,
    description: `Leaderboard showing the top 200 ${leaderboardId} players.`
  };

  let startTime = '';
  if (leaderboardData.seasonsWithStartDate) {
    const seasonId = leaderboardData.seasonId;
    const seasons = leaderboardData.seasonsWithStartDate;
    startTime = seasons[seasonId];
  }

  return {
    leaderboardInfo,
    lastUpdated,
    startTime
  };
}

export default connect(mapStateToProps)(LeaderboardHeading);
