import React, { Component } from 'react';

import Leaderboard from '../Leaderboard/Leaderboard';
import './App.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              Hearthstone Leaderboards
            </a>
          </div>
        </nav>
        <div className="container">
          <div className="row">
            <div className="col">
              <Leaderboard />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
