import React from 'react';
import { ScaleLoader } from 'react-spinners';

import './Spinner.css';

const Spinner = () => (
  <div className="spinner">
    <ScaleLoader color={'#777'} />
  </div>
);

export default Spinner;
