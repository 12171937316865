import axios from 'axios';

export const leaderboardService = {
  getLeaderboard
};

function getLeaderboard(region, leaderboardId, seasonId) {
  const url = `${process.env.REACT_APP_API_URL}/leaderboards`;
  const params = { region, leaderboardId };

  if (seasonId) {
    params.seasonId = seasonId;
  }

  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    crossdomain: true,
    params
  };

  return axios.get(url, options);
}
