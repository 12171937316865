export const leaderboardConstants = {
  GET_LEADERBOARD_REQUEST: 'GET_LEADERBOARD_REQUEST',
  GET_LEADERBOARD_SUCCESS: 'GET_LEADERBOARD_SUCCESS',
  GET_LEADERBOARD_FAILURE: 'GET_LEADERBOARD_FAILURE'
};

export const leaderboardIds = {
  BG: 'Battlegrounds',
  STD: 'Standard',
  WLD: 'Wild'
};
