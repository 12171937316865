import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { leaderboardActions } from './Leaderboard.actions';

class LeaderboardFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      region: 'US',
      leaderboardId: 'STD',
      seasonId: '',
      filter: ''
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.getLeaderboard = this.getLeaderboard.bind(this);
  }

  handleFilterChange(event) {
    this.setState({ filter: event.target.value });
    this.props.setFilter(event.target.value);
  }

  clearFilter() {
    this.setState({ filter: '' });
    this.props.setFilter('');
  }

  handleSeasonChange(event) {
    const { region, leaderboardId } = this.state;
    this.setState({ seasonId: event.target.value });
    this.props.getLeaderboard(region, leaderboardId, event.target.value);
  }

  getLeaderboard(region, leaderboardId, seasonId) {
    let _seasonId = seasonId;

    // Reset season ID when switching from or to battleground leaderboard
    if (
      seasonId &&
      (this.state.leaderboardId === 'BG' || leaderboardId === 'BG')
    ) {
      _seasonId = '';
    }

    this.setState({
      region,
      leaderboardId,
      seasonId: _seasonId
    });
    this.props.getLeaderboard(region, leaderboardId, _seasonId);
  }

  getBattlegroundSeasonCount() {
    const start = moment.utc([2021, 1, 1]);
    const end = moment.utc();
    const seasons = Math.ceil(end.diff(start, 'months', true)) + 1;

    return seasons;
  }

  render() {
    const { region, leaderboardId, seasonId } = this.state;
    const STANDARD = 'STD';
    const WILD = 'WLD';
    const BG = 'BG';
    const US = 'US';
    const EU = 'EU';
    const AP = 'AP';

    return (
      <div className="row filter-container sticky-top no-gutters">
        <div className="col">
          <div
            className="btn-toolbar"
            role="toolbar"
            aria-label="Filter Toolbar"
          >
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="Leaderboard ID Filter"
            >
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(region, STANDARD, seasonId)}
                disabled={leaderboardId === STANDARD}
              >
                Standard
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(region, WILD, seasonId)}
                disabled={leaderboardId === WILD}
              >
                Wild
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(region, BG, seasonId)}
                disabled={leaderboardId === BG}
              >
                Battlegrounds
              </button>
            </div>
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="Region Filter"
            >
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(US, leaderboardId, seasonId)}
                disabled={region === US}
              >
                US
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(EU, leaderboardId, seasonId)}
                disabled={region === EU}
              >
                EU
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.getLeaderboard(AP, leaderboardId, seasonId)}
                disabled={region === AP}
              >
                AP
              </button>
            </div>
            {leaderboardId === BG ? (
              <div className="input-group mr-2">
                <select
                  className="form-control"
                  value={this.props.seasonId}
                  onChange={this.handleSeasonChange}
                >
                  {[...Array(this.getBattlegroundSeasonCount())].map((_, i) => {
                    const seasonName = `Season ${i + 1}`;

                    return (
                      <option key={i} value={i}>
                        {seasonName}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div className="input-group mr-2">
                <select
                  className="form-control"
                  value={this.props.seasonId}
                  onChange={this.handleSeasonChange}
                >
                  {Object.keys(this.props.seasons).map(key => {
                    const time = this.props.seasons[key];
                    const month = moment(time)
                      .utc()
                      .format('MMMM YYYY');

                    return (
                      <option key={key} value={key}>
                        {'Season ' + key + ' - ' + month}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="input-group">
              <input
                type="text"
                className="form-control has-clear"
                placeholder="Search for a Player"
                aria-label="Search..."
                value={this.state.filter}
                onChange={this.handleFilterChange}
              />
              <button
                className="btn-clear"
                type="button"
                onClick={this.clearFilter}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { leaderboardData } = state.leaderboard;
  const seasonId = leaderboardData.seasonId;
  const seasons = leaderboardData.seasonsWithStartDate
    ? leaderboardData.seasonsWithStartDate
    : {};

  return {
    seasonId,
    seasons
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLeaderboard: (region, leaderboardId, seasonId) => {
      dispatch(
        leaderboardActions.getLeaderboard(region, leaderboardId, seasonId)
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardFilters);
