import React, { Component } from 'react';
import { connect } from 'react-redux';

import { leaderboardActions } from './Leaderboard.actions';
import LeaderboardHeading from './LeaderboardHeading';
import LeaderboardFilters from './LeaderboardFilters';
import LeaderboardTable from './LeaderboardTable';
import './Leaderboard.css';

class Leaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };

    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    this.props.getLeaderboard();
  }

  setFilter(filter) {
    this.setState({
      filter
    });
  }

  render() {
    return (
      <React.Fragment>
        <LeaderboardFilters setFilter={this.setFilter} />
        <LeaderboardHeading />
        <LeaderboardTable filter={this.state.filter} />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeaderboard: (region, leaderboardId, seasonId) => {
      dispatch(
        leaderboardActions.getLeaderboard(region, leaderboardId, seasonId)
      );
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Leaderboard);
