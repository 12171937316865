import moment from 'moment';

export const formatLastUpdated = lastUpdated => {
  return moment.utc(lastUpdated, 'YYYY/MM/DD hh:mm:ss ddd').fromNow();
};

export const capitalize = s => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};
